import Typed from 'typed.js';
import inView from 'in-view';
import '@lottiefiles/lottie-player';

export default {
	init() {
		console.log('home');

		new Typed('#typed', {
			stringsElement: '#typed-strings',
			loop: true,
			backSpeed: 50,
			typeSpeed: 100,
		});

		inView('.where_start_section').once('enter', startAnimation);

		function startAnimation() {
			const text_list = document.getElementById('text_list').children;
			let index = 1;

			console.log('aniamtion');

			setInterval(() => {
				Array.from(text_list).forEach(function (element) {
					element.classList.remove('active');
				});
				text_list[index].classList.add('active');
				index = index < text_list.length - 1 ? index + 1 : 0;
			}, 4000);

			setTimeout(() => {
				lottiePlayer.play();
			}, 200);
		}

		const lottiePlayer = document.querySelector('lottie-player');
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
