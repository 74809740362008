export default {
	init() {
		console.log('calendarione');

		jQuery(document).ready(function ($) {
			let currentPage = 1;

			let loadMoreBtn = $('#loadMorePastEvents');
			let pastEvents = $('#pastEvents');

			let maxPages = loadMoreBtn.attr('data-max-pages');

			loadMoreBtn.bind('click', function () {
				currentPage++;

				loadMoreBtn.prop('disabled', true);

				$.ajax({
					type: 'POST',
					url: '/wp-admin/admin-ajax.php',
					//dataType: 'json',
					data: {
						action: 'calendar_load_more',
						paged: currentPage,
					},
					success: function (res) {
						pastEvents.append(res);
						if (currentPage >= maxPages) {
							loadMoreBtn.hide();
						} else {
							loadMoreBtn.prop('disabled', false);
						}
					},
					fail: function (err) {
						console.log('There was an error: ' + err);
						loadMoreBtn.prop('disabled', false);
					},
				});
			});
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
