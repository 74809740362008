import UIkit from 'uikit';

jQuery(document).ready(function($) {

    // Perform AJAX login on form submit
    $('form#login').on('submit', function(e){
        $('form#login .status').removeClass('uk-text-danger').text('');
        $('.spinner_box').show();
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_login_object.ajaxurl,
            data: {
              'action': 'ajaxlogin', //calls wp_ajax_nopriv_ajaxlogin
              'username': $('form#login #username').val(),
              'password': $('form#login #password').val(),
              'security': $('form#login #security').val() },
            success: function(data){
              $('.spinner_box').hide();
              if (data && data.loggedin == true){
                UIkit.modal('#login-modal').hide();
                document.location.href = ajax_login_object.redirecturl;
              } else if(!data || data.loggedin == false){
                var error_text = data.message;
                $('form#login .status').addClass('uk-text-danger').html(error_text);
              }
            },
        });
        e.preventDefault();
    });

});
