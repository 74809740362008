import UIkit from 'uikit';
import tippy from 'tippy.js';

jQuery(document).ready(function($) {

    const otherRoleInput = document.getElementById("role-other")
    
    otherRoleInput && otherRoleInput.addEventListener('focus', (event)=> {
      document.getElementById("other").checked = true;
    }) 

    // Path based fields - change form fields based on the role 
    const pathBasedFields = document.getElementById("path_based_fields") 
    const teacherFields = document.getElementById("teacher_fields") 
    const notTeacherFields = document.getElementById("not_teacher_fields") 
    const roleRadios = document.querySelectorAll('input[name="role"]')

    roleRadios && roleRadios.forEach((elem) => {
      elem.addEventListener("change", function(event) {
        var itemValue = event.target.value;
        console.log(itemValue);
        if(itemValue === '1'){
          pathBasedFields.innerHTML = teacherFields.innerHTML
        } else {
          pathBasedFields.innerHTML = notTeacherFields.innerHTML
        }

        tippy('.right_tooltip', {
          content: (reference) => reference.getAttribute('title'),
          placement: 'right',
          theme: 'light',
        });

      });
    });

    // Perform AJAX registration on form submit
    $('form#otb_registration_form').on('submit', function(e){

      $('form#otb_registration_form .status').removeClass('uk-text-danger').text('');
      $('.spinner_box').show();

      const data = {
        'action': 'ajaxsignup', //calls wp_ajax_nopriv_ajaxsignup
        'otb_user_password': $('form#otb_registration_form #otb_user_password').val(),
        'otb_user_email': $('form#otb_registration_form #otb_user_email').val(),
        'otb_user_first': $('form#otb_registration_form #otb_user_first').val(),
        'otb_user_last': $('form#otb_registration_form #otb_user_last').val(),
        'teacher-age': $('form#otb_registration_form #age').val(),
        'teacher-where': $('form#otb_registration_form #where-context').val(),
        'teacher-role': $('form#otb_registration_form input[name="role"]:checked').val(),
        'role-other': $('form#otb_registration_form #role-other').val(),
        
        'teacher-context': $('form#otb_registration_form #teacher-context').val(),
        'teacher-code': $('form#otb_registration_form #teacher-code').val(),
        'teacher-organization': $('form#otb_registration_form #teacher-organization').val(),
        'kids-age': $('form#otb_registration_form #kids-age').val(),
        'teacher-school-subject': $('form#otb_registration_form #school-subject').val(),
        
        'newsletter': document.getElementById('newsletter-consent').checked ? 'yes' : '',
        'security': $('#security-registration').val()
      }

      console.log(data)


      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: ajax_login_object.ajaxurl,
        data: data,

        success: function(data){
          if(!data || data.signup === false){
            var error_text = data.message;
            $('form#otb_registration_form .status').addClass('uk-text-danger').text(error_text);
            $('.spinner_box').hide();
          } else if(data.signup == true){
            document.location.href = data.redirecturl;
          }
        },
        error: function (error) {
          console.log(error);
          $('.spinner_box').hide();
        },
        complete: function () {
          console.log('complete');
          $('.spinner_box').hide();
        }
      });

      e.preventDefault();

    });

});
