
jQuery(document).ready(function($) {

  $('.download_xlsx').on('click', function(e){
    console.log('XLSX!!');
    $('.loader').addClass('waiting');
    $('.loader').removeClass('hide');

    var assessment_id = $(this).attr('data-assessment-id');
    var teacher_name = $(this).attr('data-teacher-name');
    var lesson = $(this).attr('data-lesson');
    var assessment_label = $(this).attr('data-assessment-label');

    var data = 'action=download_xlsx&assessment_id=' + assessment_id + '&teacher_name=' + teacher_name + '&lesson=' + lesson +  '&assessment_label=' + assessment_label;
    var xhr = new XMLHttpRequest();
    
    xhr.open('POST', ajax_login_object.ajaxurl, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.onload = function(e) {
        $('.loader').addClass('hide');
        $('.loader').removeClass('waiting');
        if (this.status == 200) {
            var blob = new Blob([this.response], {type: 'application/vnd.ms-excel'});
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            // a.download = "data.xls";

            var rightNow = new Date();
            var formatted_date = rightNow.toISOString().slice(0,10).replace(/-/g,"");
            a.download = formatted_date+'-'+teacher_name+'-'+lesson+'-'+assessment_label+'.xlsx';

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            console.log('Unable to download excel.')
        }
    };
    xhr.send(data);
  })

});