export default {
	init() {
		console.log('challenge');

		jQuery(document).ready(function ($) {
			let currentPage = 1;

			let loadMoreBtn = $('#loadMoreChSub');
			let list = $('#challengeSubmissionsList');

			let maxPages = loadMoreBtn.attr('data-max-pages');
			let slug = loadMoreBtn.attr('data-category-slug');

			loadMoreBtn.bind('click', function (e) {
				e.preventDefault();
				currentPage++;

				loadMoreBtn.addClass('isLoading');

				$.ajax({
					type: 'POST',
					url: '/wp-admin/admin-ajax.php',
					//dataType: 'json',
					data: {
						action: 'challenge_submissions_load_more',
						paged: currentPage,
						slug: slug,
					},
					success: function (res) {
						list.append(res);
						if (currentPage >= maxPages) {
							loadMoreBtn.hide();
						} else {
							loadMoreBtn.removeClass('isLoading');
						}
					},
					fail: function (err) {
						console.log('There was an error: ' + err);
						loadMoreBtn.removeClass('isLoading');
					},
				});
			});
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
